<nx-pageTitle-box
  class="mb-3"
  [label]="'Dashboard'"
  [icon]="'icon-dashboard'"
  [iconToolTip]="'Dashboard'">
  <ng-template nxTemplate="end">
    <app-date-selector
      class="date-selector"
      className="white"
      [displayLabel]=false
      (date)="dateChanged($event)"
      [initialDate]="this.date">
    </app-date-selector>
    <app-location-selector
      class="ml-2"
      [style]="'dark'"
      [multi]="false"
      [stateKey]="'dashboardComponent'"
      [storageType]="'local'"
      (locations)="selectLocation($event)">
    </app-location-selector>
    <p-overlayPanel #op>
      <ng-template pTemplate="content">
        <div class="max-h-[17rem] w-[200px] overflow-y-auto">
          <div class="flex flex-col"> 
            <div class="border-b mb-2">
              <div class="text-base font-medium leading-3r">Aktive Widgets</div>
              <ul cdkDropList
                [cdkDropListData]="displayedWidgets"
                (cdkDropListDropped)="dropWidget($event)">
                <li
                  class="displayedColumn flex flex-row text-base text-[#848488] font-light leading-3"
                  cdkDrag 
                  cdkDragLockAxis="y"
                  *ngFor="let column of displayedWidgets">
                  <div class="pr-2 py-1">
                    <p-checkbox
                    [disabled]="displayedWidgets?.length === 1"
                    [binary]="true"
                    (click)="displayedWidgets?.length > 1 ? onRemoveFromDisplayedWidgets($event, column) : null"
                    [ngModel]="true">
                  </p-checkbox>
                  </div>
                  <div class="pt-[3px] flex-grow">
                    {{getHeaderForWidget(column)}}
                  </div>
                  <div class="pr-2 py-1 cursor-grabbing" cdkDragHandle>
                    <i [ngClass]="'icon-expand'"></i></div>
                </li>
              </ul>
            </div>
            <div class="border-b mb-2">
              <div class="text-base justify-center items-center font-medium leading-3r">Verfügbare Widgets</div>
              <ul
                class="text-base text-[#848488] font-light leading-3">
                <li *ngFor="let widget of notDisplayedWidgets" class="flex flex-row">
                  <div class="pr-2 flex">
                    <input type="checkbox" [checked]="false" (change)="onAddToDisplayedWidgets(widget)">
                  </div>
                  <div class="py-1 flex-grow">
                    {{getHeaderForWidget(widget)}}
                  </div>
                  <div></div>
                </li>
              </ul>
              <ng-container *ngIf="!notDisplayedWidgets || notDisplayedWidgets?.length === 0">
                <div class="text-base text-[#848488] font-light">-</div>
              </ng-container>
            </div>
            <nx-default-button
              (click)="resetWidgets()"
              [width]="'full'"
              class="w-full"
              [style]="'dark'"
              [label]="'Widgets zurücksetzen'">
            </nx-default-button>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>
      <nx-default-button
      (click)="op.toggle($event)"
      class="ml-2"
      [style]="'dark'"
      [icon]="'icon-settings'">
    </nx-default-button>
  </ng-template>
</nx-pageTitle-box>

<div class="grid grid-cols-2 gap-4">
  <ng-container *ngFor="let widgetName of displayedWidgets">
    <ng-container *ngIf="getWidget(widgetName)?.type === 'DashboardTaskListComponent'">
      <app-dashboard-task-list
        #widget
        [widget]="getWidget(widgetName)"
        [locationId]="selectedLocation?.id"
        [setDate]="date" />
    </ng-container>
    <ng-container *ngIf="getWidget(widgetName)?.type === 'DashboardTourListComponent'">
      <app-dashboard-tour-list
        #widget
        [widget]="getWidget(widgetName)"
        [locationId]="selectedLocation?.id"
        [setDate]="date" />
    </ng-container>
  </ng-container>
</div>
