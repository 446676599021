import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ComponentState, ExpandService, StateVar } from '@nexato/nx-core-module';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { DashboardTaskListSettings, DateType } from '../dashboard-task-list/dashboard-task-list.component';
import { DashboardTourListSettings } from '../dashboard-tour-list/dashboard-tour-list.component';

export interface Widget {
  field: string;
  label: string;
  showSpacer: boolean;
  settings?: DashboardTaskListSettings | DashboardTourListSettings | undefined;
  type: string;
}

export interface WidgetComponent {
  reset(): void;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{

  @ViewChildren('widget') widgets: QueryList<WidgetComponent>;

  availableWidgets: Widget[] = [
    { field: 'openTours', label: 'Geplante Touren', showSpacer: true, type: "DashboardTourListComponent", settings:
      {
        states: ['NEW'],
        sortOptions: ['id', 'name', 'resource.name']
      }
    },
    { field: 'activeTours', label: 'Laufende Touren', showSpacer: true, type: "DashboardTourListComponent", settings:
      {
        states: ['RUNNING'],
        sortOptions: ['id', 'name', 'resource.name']
      }
    },
    { field: 'finishedTours', label: 'Abgeschlossene Touren', showSpacer: true, type: "DashboardTourListComponent", settings:
      {
        states: ['FINISHED'],
        sortOptions: ['id', 'name', 'resource.name']
      }
    },
    { field: 'pickupResourceOut', label: 'Selbstabholer Ausgang', showSpacer: true, type: "DashboardTaskListComponent", settings:
      {
        handlingType: 'PICKUP',
        types: [ 'nexcore_rental_resourceAssignmenmt_out'],
        states: ['ASSIGNED'],
        sortOptions: ['id', 'dueDateTimePeriod'],
        dateType: DateType.assignmentDueDateTime
      }
    },
    { field: 'pickupResourceIn', label: 'Selbstabholer Eingang', showSpacer: true, type: "DashboardTaskListComponent", settings:
      {
        handlingType: 'PICKUP',
        types: [ 'nexcore_rental_resourceAssignmenmt_in'],
        states: ['ASSIGNED'],
        sortOptions: ['id', 'dueDateTimePeriod'],
        dateType: DateType.assignmentDueDateTime
      }
    },
    { field: 'allResourceOut', label: 'Ressourcen Ausgang', showSpacer: true, type: "DashboardTaskListComponent", settings:
      {
        types: [ 'nexcore_rental_resourceAssignmenmt_out'],
        sortOptions: ['id', 'dueDateTimePeriod', 'type', 'state'],
        states: ['ASSIGNED', 'COMPLETED'],
        dateType: DateType.assignmentDueDateTime
      }
    },
    { field: 'allResourceIn', label: 'Ressourcen Eingang', showSpacer: true, type: "DashboardTaskListComponent", settings:
      {
        types: [ 'nexcore_rental_resourceAssignmenmt_in'],
        sortOptions: ['id', 'dueDateTimePeriod', 'type', 'state'],
        states: ['ASSIGNED', 'COMPLETED'],
        dateType: DateType.assignmentDueDateTime
      }
    },
    { field: 'notCompletedRefuel', label: 'Nachtanken nicht abgeschlossen', showSpacer: true, type: "DashboardTaskListComponent", settings:
      {
        types: [ 'nexcore_rental_resourceAssignmenmt_refuel'],
        states: ['ASSIGNED'],
        sortOptions: ['id', 'dueDateTimePeriod'],
        includeOverDue: true
      }
    }
  ];

  defaultWidgets = [
    'openTours',
    'activeTours',
    'pickupResourceOut',
    'pickupResourceIn',
    'finishedTours'
  ];

  displayedWidgets = [...this.defaultWidgets];
  notDisplayedWidgets;
  displayedWidgetsStateKey = 'DashboardComponent.displayedWidgets';

  @ViewChild('drawerRight') drawerRight: any;
  public date = new Date();
  initialDate = new Date();
  selectedLocation: LocationEntity;
  public componentStateCount = 0;

  componentState: ComponentState<DashboardComponent>;
  selectedLocationDashboard: StateVar<string>;
  constructor(
    private cdRef:ChangeDetectorRef,
    public expandService: ExpandService
  ) {
    this.setDisplayedWidgets();
  }

  ngOnInit() {
    this.expandService.setExpandState(false);
  }

  selectLocation(location: LocationEntity[]){
    if (location && location.length > 0) {
      this.selectedLocation = location[0];
      this.cdRef.detectChanges();
    }
  }

  dateChanged($event: any){
    this.date = $event;
  }

  getHeaderForWidget(widget: string): string {
    const wdg = this.availableWidgets.find((w) => w.field === widget);
    return wdg ? wdg.label : '';
  }

  dropWidget($event){
      //    // If the item is dropped in the same list, reorder the items
      if ($event.previousContainer === $event.container) {
        moveItemInArray($event.container.data, $event.previousIndex, $event.currentIndex);
      }
      this.saveDisplayedWidgets();
    }

  onRemoveFromDisplayedWidgets($event, column: string){
    $event.stopPropagation()
    this.displayedWidgets = this.displayedWidgets.filter(col => col !== column);
    this.notDisplayedWidgets.push(column);
    this.saveDisplayedWidgets();
  }


  onAddToDisplayedWidgets(column: string){
    this.notDisplayedWidgets = this.notDisplayedWidgets.filter(col => col !== column);
    this.displayedWidgets.push(column);
    this.saveDisplayedWidgets();
  }

  saveDisplayedWidgets(){
    let displayedWidgetsString = JSON.stringify(this.displayedWidgets);
    localStorage.setItem(this.displayedWidgetsStateKey, displayedWidgetsString);
  }

  setNotDisplayedWidgets(){
    this.notDisplayedWidgets = this.availableWidgets.map(col => col.field).filter(col => !this.displayedWidgets.includes(col));
  }

  setDisplayedWidgets(){
    try {
      // try to load the displayed columns from local storage
      let displayWidgetsString = localStorage.getItem(this.displayedWidgetsStateKey);
      let displayWidgetsFromLocalStorage = JSON.parse(displayWidgetsString);
      // check if all columns from the stored state are still available
      // if not, use the default columns
      if(displayWidgetsFromLocalStorage){
        let allWidgetsAvailable = displayWidgetsFromLocalStorage.every(col => this.availableWidgets.find(ac => ac.field === col));
        if(allWidgetsAvailable){
          this.displayedWidgets = displayWidgetsFromLocalStorage;
        } else {
          this.displayedWidgets = [...this.defaultWidgets];
          localStorage.removeItem(this.displayedWidgetsStateKey);
        }
      }
    } catch (error) {
      localStorage.removeItem(this.displayedWidgetsStateKey);
      this.resetWidgets();
    }
    this.setNotDisplayedWidgets();
  }

  resetWidgets(){
    this.displayedWidgets = [...this.defaultWidgets];
    this.saveDisplayedWidgets();
    this.setNotDisplayedWidgets();
    this.widgets.forEach(w => w.reset());
  }

  getWidget(widgetName: string) {
    return this.availableWidgets.find(w => w.field === widgetName);
  }


}
