import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommandService, CommandTemplate, Container, ExpandService, LoadingService, Reloadable, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';
import { environment } from 'src/environments/environment';
import { Protocol, ProtocolState } from '../../../rent-module/shared/entities/protocol/protocol';
import { Order } from '../../shared/entities/order/order';
import { OrderService } from '../../shared/services/order/order.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent
extends Container<fromGraphQl.OrderQueryResponse>
  implements OnInit, OnDestroy, Reloadable
{
  public routeSubscription: Subscription;
  public id: string;
  public order: Order;
  public count = 1;
  public Order = Order;
  public tasksCount: string;
  public tasksCountCompleted: string;
  public tasksCountAssigned: string;
  public tasksCountUnAssigned: string;
  public commentsCount: string;
  public orderType: string;
  public protocolsCount:number;
  public customAttributeSections: any;
  public pageTitleMenuItems: MenuItem[]
  dialogRef: DynamicDialogRef | undefined;
  
  activeIndex: number = 0;

  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute,
    public orderService: OrderService,
    public dialog: MatDialog,
    public rolesService: RolesService,
    public expandService: ExpandService,
    private commandService: CommandService

  ) {
    super(apollo, loadingService, fromGraphQl.ORDER_QUERY, environment);
  }

  ngOnInit(): void {
    const key = 'id';
    this.routeSubscription = this.route.params
      .pipe(
        filter((params) => params[key] !== undefined),
        take(1)
      )
      .subscribe((params) => {
        if (params[key] && params[key] !== 'create') {
          this.id = params[key];
        }
        // load model (if we have an id) and the form
        this.subscribeToQuery({
          orderId: this.id,
          entity: 'Order'
        });
      });
  }

  handleSubscriptionResult(data: fromGraphQl.OrderQueryResponse): void {
    this.loadingService.setLoading(false);
    // console.log('dtaa', data);
    if (data.order) {
      this.queryRef.stopPolling();
      this.order = new Order(data.order);
      this.tasksCount = data?.tasksCount;
      this.tasksCountCompleted = data?.tasksCountCompleted;
      this.tasksCountAssigned = data?.tasksCountAssigned;
      this.tasksCountUnAssigned = data?.tasksCountUnassigned;
      this.commentsCount = data?.commentsCount;
      this.orderType = this.order.type?.toString();
      this.protocolsCount = 0;
      for (const lineItem of this.order?.lineItems ?? []){
        for (const resourceAssignment of lineItem?.resourceAssignments ?? []){
          for (const protocol of resourceAssignment?.protocols ?? []) {
              if (protocol.state=="COMPLETED" || protocol.state=="ARCHIVED") {
                this.protocolsCount ++;
              }
          }
        }
      }
      // we have to to this here, because if we directly link a function to the menuItems parameter
      // in the template, the change detection will constantely produce new instances of the 
      // menu causing it not to work properly
      this.pageTitleMenuItems = this.getAvailablePageTitleItems();

    }
    this.customAttributeSections = data?.customAttributeSections;
  }

  isEmtpyProtocol(protocols: Protocol[], type: string) {
    let isEmpty = true;
    for(const protocol of protocols ) {
      if (protocol.type?.includes(type) && protocol.state === ProtocolState.COMPLETED.valueOf()) {
        isEmpty = false;
        break;
      }
    }
    return isEmpty;
  }

  findRefillProtocol(resourceAssignment: ResourceAssignment){
    // console.log('asdfasdf')
    if(resourceAssignment?.protocols?.length > 0){
      for(const protocol of resourceAssignment?.protocols ) {
        if (protocol.type?.includes('protocol.rental.refuel') && protocol.state === ProtocolState.COMPLETED.valueOf()) {
          return protocol;
        }
      }
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  childUpdated() {
    this.queryRef?.refetch();
  }

  getAvailablePageTitleItems() {
    let pageTitleMenuItems: MenuItem[] = [];

    if(this.order.commands?.length > 0){
      this.order.commands.forEach( (command: CommandTemplate) => {
        pageTitleMenuItems.push({
          label: command.settings?.label,
          disabled: command.settings?.disabled,
          command: () => {
            this.commandService.execute(this.order, command, this);
          }
        });
      });
    }

    return pageTitleMenuItems;
  }

  reload(): void {
    this.queryRef?.refetch();
  }
}
