

<!-- header start -->
<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
    <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
      {{ getHeaderLabel()}}
    </div>
</div>
<!-- header end -->

<nx-empty-box
    *ngIf="!_task"
    [label]="'Keine Aufgabe ausgewählt.'">
</nx-empty-box>
<ng-container *ngIf="_task">
    <nx-default-box
        *ngIf="_task?.order?.number" 
        class="bg-white border-b border-gray"
        [label]="'Auftragsnummer'">
        <div class="flex items-center">
            <a [routerLink]="['/orders/order/', _task?.order?.id ]" class="jump">
                {{_task?.order?.number}}
                <i [className]="'icon icon-jump-to'"></i>
            </a>
        </div>
    </nx-default-box>
    <nx-default-box
        *ngIf="_task?.order?.type?.toString()" 
        class="bg-white border-b border-gray"
        [label]="'Typ'">
        {{Order.getOrderType(_task?.order?.type?.toString())}}
    </nx-default-box>
    <nx-default-box 
        *ngIf="_task?.order?.customer"
        class="bg-white border-b border-gray"
        [label]="'Kunde (Nr.)'">
        <div class="flex items-center">
            <a [routerLink]="['/customers/customer/', _task?.order?.customer?.id ]" class="jump">
                {{_task?.order?.customer?.getFullName()? _task?.order?.customer?.getFullName() :'-'}} {{_task?.order?.customer?.number? '(' +
                _task?.order?.customer.number+ ')': ''}}
                <i [className]="'icon icon-jump-to'"></i>
            </a>
        </div>
    </nx-default-box>
    <nx-default-box
        *ngIf="_task?.order?.location?.name"
        class="bg-white border-b border-gray"
        [label]="'Niederlassung'">
        {{_task?.order?.location?.name}}
    </nx-default-box>

    <ng-container *ngIf="_task?.formControlTemplate?.settings?.customFormName === 'rentalResourceAssignmentReviewForm'">
        <app-rental-resource-assignment-review-form 
            (complete)="completeTask($event)"
            [task]="_task">
        </app-rental-resource-assignment-review-form>
    </ng-container>

    <ng-container *ngIf="_task?.formControlTemplate?.key === 'resourceRefuelForm'">
        <app-rental-resource-assignment-refuel-form 
            (complete)="completeTask($event)"
            [task]="_task">
        </app-rental-resource-assignment-refuel-form>
    </ng-container>
    
</ng-container>
