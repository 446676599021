import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { debounceTime, Subject, take } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { LocationService } from '../../shared/services/location/location.service';
import { MyTasksListDataSource } from './myTasksListDataSource';

@Component({
  selector: 'tasks-list',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class TasksComponent {

  dataSource: any;
  public selectedTask: Task;

  // text
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;
  private textSubject = new Subject<string>();
  public textModel: string;
  public defaultTextModel: string = undefined;

  // types
  public typesModel: any[] = [];
  public typeOptions = [
   { id: 'nexcore_rental_resourceAssignmenmt_review', name: 'Prüfen', label: 'Prüfen' },
   { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' }
  ];
  // load all types by default
  // defaultTypesModel: what the user sees (nothing selected)
  public defaultTypesModel = [];
  // emptyTypesModel: what is actually send to the server: only review tasks
  public emptyTypesModel = [
    { id: 'nexcore_rental_resourceAssignmenmt_review' },
    { id: 'nexcore_rental_resourceAssignmenmt_refuel' }
  ]

  // locations - options will be generated as a result of the locationService.getLocations() call
  public locationsModel: any[] = [];
  public locationsOptions = [];
  public defaultLocationsModel = undefined; // load all locations by default

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: "desc"},
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"},
    { label: 'Kunde aufsteigend', fieldName: 'customer', direction: "asc" },
    { label: 'Kunde absteigend', fieldName: 'customer', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  constructor(
    private apollo: Apollo,
    private changeDetectorRef: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe(locations => {
      this.locationsOptions = locations;
    });
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.typesModel = this.defaultTypesModel;
    this.locationsModel = this.defaultLocationsModel;
    this.textSubject.pipe(
      debounceTime(500)
    ).subscribe(text => {
      this.textModel = text;
      this.filter();
    });
    this.dataSource = new MyTasksListDataSource(this.apollo, this.changeDetectorRef, this.dataLoadedCallback.bind(this));
    this.loadTasks();
  }

  loadTasks(){
    this.dataSource?.loadTasks({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      locationIds: this.locationsModel?.map(location => location.id),
      types: this.typesModel.length == 0 ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel?.map(type => type.id),
      text: this.textModel
    });
  }

  filter(){
    this.refetchTasks();
  }

  sort() {
    this.refetchTasks();
  }

  onSearchInput(text: string) {
    this.textSubject.next(text);
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }

  refetchTasks(force = false): void {
    this.dataSource.refetchQuery({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      locationIds: this.locationsModel?.map(location => location.id),
      sortDirection: this.sortModel?.direction,
      types: this.typesModel.length == 0 ?  this.emptyTypesModel?.map(type => type.id) : this.typesModel?.map(type => type.id),
      text: this.textModel,
    }, force);
  }

  isSelected(task: Task): boolean {
    if(this.selectedTask?.id === task?.id){
      return true;
    }
    return false;
  }

  selectTask(task: Task) {
    if(this.selectedTask === task){
      this.selectedTask = undefined;
      return;
    } else {
      this.selectedTask = task;
    }
  }

  dataLoadedCallback(tasks: Task[]) {
    // no task is selected, set the first
    if(!this.selectedTask && tasks?.length > 0){
      this.selectedTask = tasks[0];
    }
    // if the selected task is not in the list anymore, select the first task
    if(this.selectedTask && !tasks.find(task => task.id === this.selectedTask.id)){
      this.selectedTask = tasks[0];
    }
  }
  
}
