import { NXDataSourcePrime } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { ResourceAssignment } from '../../shared/entities/resourceAssignment/resourceAssignment';
import * as fromGraphQl from './graphql';

export class ResourceAssignmentDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.RESOURCE_ASSIGNMENTS_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.ResourceAssignmentsQueryResponse): void {
    super.setSubject(
      data?.resourceAssignments?.content ? ResourceAssignment.createResourceAssignments( data.resourceAssignments?.content) : [],
      data?.resourceAssignments?.totalElements ? data.resourceAssignments.totalElements : 0
    );
  }

  loadResourceAssignments(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
