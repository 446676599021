import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { GrowthBookService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { PageModel, SortModel } from 'src/app/rent-module/components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { OrderTaskListViewDataSource } from './orderTaskListViewDataSource';

@Component({
  selector: 'app-order-task-list-view',
  templateUrl: './order-task-list-view.component.html',
  styleUrls: ['./order-task-list-view.component.scss'],
})
export class OrderTaskListViewComponent implements OnDestroy {

  defaultColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'extendedState'
  ];

  // types
  public typesModel: any[] = [];
  public typeOptions = [
    { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
    { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
    { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' }
  ];
  public defaultTypesModel = undefined; // load all types by default

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: "asc"},
    { label: 'Typ absteigend', fieldName: 'type', direction: "desc" },
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: "asc" },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // states (not filterable at the moment)
  public states = ['NEW', 'ASSIGNED', 'UNASSIGNED', 'COMPLETED'];

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  public _orderId: string | undefined;

  // indicates loading
  public loading = false;

  public dataSource: OrderTaskListViewDataSource;

  @Input() set orderId(orderId: any) {
    this._orderId = orderId;
    this.loadTasks();
  }

  constructor(
    private apollo: Apollo,
    public taskService: TasksService,
    private cdRef:ChangeDetectorRef,
    private growthBookService: GrowthBookService,
  ) { 
    if(this.growthBookService.isFeatureEnabled('rentalResourceAssignmentReviewWorkflow')){
      this.typeOptions.push({ id: 'nexcore_rental_resourceAssignmenmt_review', name: 'Prüfen', label: 'Prüfen' })
    }
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.typesModel = this.defaultTypesModel;
    this.dataSource = new OrderTaskListViewDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  loadTasks() {
    if (this._orderId) {
      this.dataSource.loadTaskList({
        orderId: this._orderId,
        states: this.states,
        types: this.typesModel?.map(type => type.id),
        sortProperty: this.sortModel?.fieldName,
        sortDirection: this.sortModel?.direction,
        pageNumber: this.pageModel?.pageNumber,
        pageSize: this.pageModel?.pageSize,
      });
    }
  }

  refetchTasks(force = false): void {
    this.dataSource.refetchQuery({
      orderId: this._orderId,
      states: this.states,
      types: this.typesModel?.map(type => type.id),
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
    }, force);
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTasks();
  }

}
