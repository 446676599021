import { Injectable } from "@angular/core";
import { CommandInput, CommandService, CommandTemplate, Flow, HasCommands } from "@nexato/nx-core-module";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { Order } from "src/app/order-module/shared/entities/order/order";
import { OrderService } from "src/app/order-module/shared/services/order/order.service";

@Injectable({
    providedIn: 'root'
  })
export class RentalOrderCreatePDFCommandCustomFlow implements Flow {

    dialogRef: DynamicDialogRef | undefined;

    constructor(
        public orderService: OrderService,
    ) { }

    executeFlow(commandService: CommandService, hasCommands: HasCommands,
      commandTemplate: CommandTemplate, commandInput: CommandInput,
      completionCallback: () => void) {

        if (hasCommands && hasCommands instanceof Order) {
            const order = hasCommands as Order;
            this.orderService?.downloadPDF(order);
            completionCallback();
        }
    
        
    }
  
  }