
<ng-container>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>
  <!-- Pagination start -->
  <p-paginator 
    [dropdownAppendTo]="'body'"
    (onPageChange)="onPageChange($event)" 
    [first]="pageModel?.pageNumber * pageModel?.pageSize"
    [rows]="pageModel?.pageSize"
    [totalRecords]="dataSource?.totalElements"
    [showCurrentPageReport]="true" 
    currentPageReportTemplate="{first} - {last} von {totalRecords}"
    [rowsPerPageOptions]="[15, 20, 50]">
  </p-paginator>
  <!-- Pagination end -->

<ng-template #table>

  <div class="bodyWrapper bg-white h-[calc(100%-100px)]">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Auftragsliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
      </div>
    </div>
    <!-- (onLazyLoad)="loadData($event)" -->
    <p-table
      #dt1
      [lazy]="true"
      
      [value]="dataSource?.data"
      [scrollable]="true"
      [paginator]="false"
      [rows]="15"
      [loading]="loading"
      [rowsPerPageOptions]="[15, 20, 50]"
      [totalRecords]="dataSource?.totalElements"
      scrollHeight="flex"
      [sortField]="sortModel?.fieldName"
      [sortOrder]="sortModel?.direction === 'asc' ? 1 : -1"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} von {totalRecords}"
      paginatorStyleClass="rounded-b-md"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="6">
            <div class="flex flex-row">
              <div class="basis-2/3 flex flex-row gap-2">
                <nx-filter-multiSelect
                  [className]="'table-filter'"
                  [filter]="false"
                  [showToggleAll]="false"
                  [showHeader]="false"
                  [appendTo]="'body'"
                  [(ngModel)] = "orderTypesModel"
                  [options]=orderTypeOptions
                  [showClear]="true"
                  placeholder="Typ"
                  (onClear)="refetchResourceAssignments()"
                  (onChange)="refetchResourceAssignments()"
                  optionLabel="name">
                    <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option?.name }}</span>
                        </div>
                    </ng-template>
                  </nx-filter-multiSelect>
                <!-- (onClear)="filter($event)"
                (onChange)="filter($event?.value)" -->
                  <nx-filter-multiSelect
                    [className]="'table-filter'"
                    [filter]="false"
                    [showToggleAll]="false"
                    [showHeader]="false"
                    [appendTo]="'body'"
                    [options]="statesOptions"
                    [(ngModel)] = "statesModel"
                    [showClear]="true"
                    [maxSelectedLabels]="1"
                    (onClear)="refetchResourceAssignments()"
                    (onChange)="refetchResourceAssignments()"
                    placeholder="Status">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                            {{option?.name}}
                          </div>
                      </ng-template>
                  </nx-filter-multiSelect>
                  <!-- (onClear)="filter($event)"
                  (onChange)="filter($event?.value)" -->
                  <nx-filter-multiSelect
                    [className]="'table-filter'"
                    [filter]="false"
                    [(ngModel)] = "locationsModel"
                    [maxSelectedLabels]="1"
                    [showClear]="true"
                    [showToggleAll]="false"
                    [showHeader]="false"
                    appendTo="body"
                    [options]="locationsOptions"
                    placeholder="Niederlassung"
                    (onClear)="refetchResourceAssignments()"
                    (onChange)="refetchResourceAssignments()"
                    optionLabel="name">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                            {{option?.name}}
                          </div>
                      </ng-template>
                  </nx-filter-multiSelect>
              </div>
              <div class="basis-1/3 flex flex-row justify-end">
                <!--  -->
                <p-dropdown
                  class="mr-2"
                  placeholder="Sortierung"
                  [(ngModel)] = "sortModel"
                  [options]="sortOptions"
                  [styleClass]="'sort'"
                  appendTo="body"
                  (onChange)="refetchResourceAssignments()"
                  optionLabel="label"
                  [showClear]="false">
                </p-dropdown>
              </div>
            </div>
          </th>
        </tr>
        <tr style="margin-top: -8px;position: relative;top: -1px;">
          <th style="width:5em">Typ</th>
          <th style="width:10rem">Auftragsnummer</th>
          <th>Kunde</th>
          <th>Startdatum</th>
          <th>Enddatum</th>
          <th>Status</th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-resourceAssignment>
          <tr class="clickable"
            [newTabNavigation]="['./orders/order', resourceAssignment?.lineItem?.order.id]">
              <td  class="iconTable"><i [className]="getIcon(resourceAssignment)"></i></td>
              <td>{{resourceAssignment?.lineItem?.order?.number}}</td>
              <td>{{getContactName(resourceAssignment)}}</td>
              <td>{{resourceAssignment?.startDateTime | date:'dd.MM.yy HH:mm'}}</td>
              <td>{{resourceAssignment?.endDateTime | date:'dd.MM.yy HH:mm'}}</td>
              <td><p-tag value="{{getState(resourceAssignment)}}" [severity]="getSeverity(resourceAssignment)"></p-tag></td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Aufträge vorhanden.</td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer"></ng-template>

  </div>
</ng-template>
