<nx-pageTitle-box
  class="mb-3"
  [label]="'Artikel'"
  [icon]="'icon-article'"
  [iconToolTip]="'Artikel'">
</nx-pageTitle-box>
<div class="bodyWrapper bg-white  h-[calc(100%-100px)]">
  <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
    <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
      Artikelliste
    </div>
    <div class="basis-1/2 flex flex-row justify-end items-center">
      <!-- empty -->
    </div>
  </div>
  <p-table #dtArticle 
    [lazy]="true"
    [value]="dataSource?.data"
    [scrollable]="true"
    [paginator]="false"
    [loading]="loading"
    scrollHeight="flex"
    [sortField]="sortModel?.fieldName"
    [sortOrder]="sortModel?.direction === 'asc' ? 1 : -1"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} von {totalRecords}"
    [resizableColumns]="true">
    <ng-template pTemplate="header">
      <tr>
        <th colspan="5">
          <div class="flex flex-row">
            <div class="basis-2/3 flex flex-row gap-2">
            </div>
            <div class="basis-1/3 flex flex-row justify-end">
              <p-dropdown
                [styleClass]="'sort'"
                appendTo="body"
                [options]="sortOptions"
                [(ngModel)] = "sortModel"
                optionLabel="label"
                [showClear]="false"
                (onChange)="refetchArticles()">
              </p-dropdown>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th>Nummer</th>
        <th>Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-article>
      <tr>
        <td>{{article?.number}}</td>
        <td>{{article.name}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8" class="emptyMessage">Keine Artikel vorhanden.</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
  </ng-template>
</div>

<!-- Pagination start -->
<p-paginator 
  [dropdownAppendTo]="'body'"
  (onPageChange)="onPageChange($event)" 
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[15, 20, 50]">
</p-paginator>
<!-- Pagination end -->
  
