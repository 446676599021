<!-- header start -->
<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
    {{_widget?.label}}
  </div>
  <div class="basis-1/2 flex flex-row justify-end items-center">
    <nx-default-button
      class="ml-2 mr-2"
      *ngIf="!expandedModel"
      (click)="toggleExpanded()"
      [tooltip]="'Volle Breite'"
      [style]="'light'"
      [icon]="'icon-after'">
    </nx-default-button>
    <nx-default-button
      class="ml-2 mr-2"
      *ngIf="expandedModel"
      (click)="toggleExpanded()"
      [tooltip]="'Halbe Breite'"
      [style]="'light'"
      [icon]="'icon-back-to'">
    </nx-default-button>
  </div>
</div>
<!-- header end -->
<div>
  <div class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white border-b">
    <div class="basis-2/3 flex flex-row gap-2">
      
    </div>
    <div class="basis-1/3 flex flex-row justify-end">

      <p-dropdown
        class="mr-2"
        placeholder="Sortierung"
        [styleClass]="'sort'"
        appendTo="body"
        optionLabel="label"
        [options]="availableSortOptions"
        [(ngModel)] = "sortModel"
        (onChange)="refetchTasks()"
        [showClear]="false">
      </p-dropdown>
    </div>
  </div>
  <div
    *ngFor="let task of dataSource?.data; last as isLast; first as isFirst; let i = index"
    task-row-wide
    class="child flex flex-row task items-center basis-full relative border-b bg-white border-gray"
    [ngClass]="{'border-b' : !isLast}"
    [task]=task
    [columns]="defaultColumns"
    [showIconMenu]="false">
  </div>

</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[5, 10, 15]">
</p-paginator>
  

