import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { TooltipOptions } from 'primeng/api';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';
import { ComponentState, PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { Widget, WidgetComponent } from '../dashboard/dashboard.component';
import { TourDataSource } from './tourDataSource';

export interface DashboardTourListSettings {
  states?: string[] | undefined;
  sortOptions?: string[] | undefined;
}

@Component({
  selector: 'app-dashboard-tour-list',
  templateUrl: './dashboard-tour-list.component.html',
  styleUrls: ['./dashboard-tour-list.component.scss']
})
export class DashboardTourListComponent implements OnDestroy, WidgetComponent{

  public expandedModel: boolean;
  public defaultExpandedModel = false;

  _locationId: string;
  @Input() set locationId(locationId: string){
    if(locationId){
      this._locationId = locationId;
      this.onChanges(); 
    }
  }

  _widget: Widget;
  _settings: DashboardTourListSettings;
  @Input() set widget(widget: Widget){
    if(widget){
      this._widget = widget;
      this._settings = widget.settings;
      this.onChanges();
    }
  };

  public _date = new Date();
  @Input() set setDate(date: any) {
    if(date){
      this._date = date;
      this.onChanges();
    }
  }

  @HostBinding('class.col-span-2') 
  get expandedClass() {
    return this.expandedModel;
  }

  public dataSource: TourDataSource;
  public loading = false;

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 5 };

  // sorting
  public sortModel: SortModel;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Tourname aufsteigend', fieldName: 'name', direction: "asc" },
    { label: 'Tourname absteigend', fieldName: 'name', direction: "desc" },
    { label: 'Fahrzeug aufsteigend', fieldName: 'resource.name', direction: "asc" },
    { label: 'Fahrzeug absteigend', fieldName: 'resource.name', direction: "desc"}
  ];
  public availableSortOptions = this.sortOptions;
  public defaultSortModel: SortModel = this.sortOptions[0];

  tooltipOptions: TooltipOptions = {
    hideDelay: 150,
    showDelay: 300,
    tooltipEvent: 'hover',
    tooltipPosition: 'bottom'
  };

  constructor(
    private apollo: Apollo,
  ) {
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.expandedModel = this.defaultExpandedModel;
  }

  onChanges() {
    if (this._widget && this._date) {
      if(!this.dataSource) {
        this.availableSortOptions = this._widget?.settings?.sortOptions ? this.sortOptions.filter(option => this._widget?.settings?.sortOptions.includes(option.fieldName)) : this.sortOptions;
        this.setStateKey("DashboardTourListComponent." + this._widget.field);
        let state = this.loadState();
        this.pageModel = state?.pageModel ? state.pageModel : this.defaultPageModel;
        this.expandedModel = state?.expandedModel ? state.expandedModel : this.defaultExpandedModel;
        // we´re filtering here for the options, so that we do not get any problems, if we change them in future
        // we need to find a suitable option for the state
        let stateSortModel = state?.sortModel;
        let matchedSortModel = this.sortOptions.find(option => option.fieldName === stateSortModel?.fieldName && option.direction === stateSortModel?.direction);
        this.sortModel = matchedSortModel ? matchedSortModel : this.defaultSortModel;
        this.dataSource = new TourDataSource(this.apollo);
        this.dataSource.loading.subscribe((loading) => {
          this.loading = loading;
        });
        this.waitForLocationId();
      } else {
        this.refetchTours();
      }
    }
  }

  waitForLocationId() {
    const checkInterval = 50; // Zeit in Millisekunden zwischen den Prüfungen
    const maxWaitTime = 300; // Maximale Wartezeit in Millisekunden
    let waitedTime = 0;

    const intervalId = setInterval(() => {
      if (this._locationId) {
        clearInterval(intervalId);
        this.loadTours();
      } else if (waitedTime >= maxWaitTime) {
        clearInterval(intervalId);
        this.loadTours();
      } else {
        waitedTime += checkInterval;
      }
    }, checkInterval);
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }

  loadTours(): void {
    this.dataSource.loadTours({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      states: this._widget?.settings?.states ? this._widget?.settings?.states : undefined,
      locationId: this._locationId,
      fromDate: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toDate: new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString()
    });
  }

  refetchTours(): void {
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      expandedModel: this.expandedModel
    });
    this.dataSource.refetchQuery({
      pageNumber: this.pageModel?.pageNumber, 
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      states: this._widget?.settings?.states ? this._widget?.settings?.states : undefined,
      locationId: this._locationId,
      fromDate: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
      toDate: new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString()
    });
  }

  getDonePercentage(tour: any): number {
    // calculate the percentage tasks in state COMPLETED
    let completed = tour?.tasks?.filter((task: any) => task?.state === 'COMPLETED')?.length;
    // get the total number of tasks
    let total = tour?.tasks?.length;
    return Math.round((completed / total) * 100);
  }

  getProgressToolTip(tour: Tour){
    if(!tour || !tour?.tasks){
      return undefined;
    }
    let completed = tour?.tasks?.filter((task: any) => task?.state === 'COMPLETED')?.length;
    let total = tour?.tasks?.length;
    return `${completed} von ${total} erledigt`;
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchTours();
  }

  getDriverName(tour: Tour): string {
    if(tour && tour?.driver){
    return tour?.driver?.firstName + ' ' + tour?.driver?.lastName;
    }
    return '-';
  }

  toggleExpanded() {
    this.expandedModel = !this.expandedModel;
    this.storeState({
      pageModel: this.pageModel,
      sortModel: this.sortModel,
      expandedModel: this.expandedModel
    });
  }

  reset(): void {
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.expandedModel = this.defaultExpandedModel;
    this.clearState();
  }

  // state management
  private stateKey: string
  private stateStorage: 'session' | 'local' = 'session'
  setStateKey(key: string) {
    this.stateKey = key;
  }
  public loadState(): ComponentState {
      if(!this.stateKey || !this.stateStorage){
          // both must be set
          return;
      }
      try {
          let stateString = null;
          if (this.stateStorage === 'session') {
              stateString = sessionStorage.getItem(this.stateKey);
          } else {
              stateString = localStorage.getItem(this.stateKey);
          }
          if (stateString) {
              return JSON.parse(stateString);
          }
      } catch (error) {
          // in case of any error, we clear the storage
          this.clearState();
      }
  }
  public clearState() {
    localStorage.removeItem(this.stateKey);
    sessionStorage.removeItem(this.stateKey);
  }
  public storeState(componentState: ComponentState) {
    if(!this.stateKey || !this.stateStorage){
        // both must be set
        return;
    }
    try {
        if (this.stateStorage === 'session') {
            sessionStorage.setItem(this.stateKey, JSON.stringify(componentState));
        } else {
            localStorage.setItem(this.stateKey, JSON.stringify(componentState));
        }
    } catch (error) {
        // in case of any error, we clear the storage
        this.clearState();
    }
  }
}
