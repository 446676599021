import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { ResourceAssignment } from '../../shared/entities/resourceAssignment/resourceAssignment';

export const RESOURCE_ASSIGNMENTS_QUERY = gql`
query resourceAssignmentsQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $sortDirection: String,
  $resourceId: UUID,
  $states: [ResourceAssignmentState],
  $orderTypes: [OrderType],
  $orderLocationIds: [UUID]
  )
{
  resourceAssignments: getResourceAssignmentsPaged(
    filter: {
      sortRequest: {
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
      resourceId: $resourceId,
      states: $states,
      orderTypes: $orderTypes,
      orderLocationIds: $orderLocationIds,
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
        id
        text
        startDateTime
        endDateTime
        state
        lineItem{
          order{
            id
            number
            type
            customer{
              type
              companyName
              companyNameExtension
              firstName
              lastName
            }
          }
        }
      }
  }
}
`;

export interface ResourceAssignmentsQueryResponse {
  resourceAssignments: Page<ResourceAssignment>;
  loading: boolean;
}
