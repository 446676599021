
<!-- header start -->
<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/2 flex flex-row items-center ml-3 font-medium noWrap h-12">
    {{_widget?.label}}
  </div>
  <div class="basis-1/2 flex flex-row justify-end items-center">
    <nx-default-button
      class="ml-2 mr-2"
      *ngIf="!expandedModel"
      (click)="toggleExpanded()"
      [tooltip]="'Volle Breite'"
      [style]="'light'"
      [icon]="'icon-after'">
    </nx-default-button>
    <nx-default-button
      class="ml-2 mr-2"
      *ngIf="expandedModel"
      (click)="toggleExpanded()"
      [tooltip]="'Halbe Breite'"
      [style]="'light'"
      [icon]="'icon-back-to'">
    </nx-default-button>
  </div>
</div>
<!-- header end -->
<div class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white border-b">
  <div class="basis-2/3 flex flex-row gap-2">
    
  </div>
  <div class="basis-1/3 flex flex-row justify-end">

    <p-dropdown
      class="mr-2"
      placeholder="Sortierung"
      [styleClass]="'sort'"
      appendTo="body"
      optionLabel="label"
      [options]="availableSortOptions"
      [(ngModel)] = "sortModel"
      (onChange)="refetchTours()"
      [showClear]="false">
    </p-dropdown>
  </div>
</div>
<div
  *ngFor="let tour of dataSource?.data; last as isLast; first as isFirst; let i = index"
  class="child flex flex-row task items-center basis-full relative border-b bg-white border-gray h-[48px]"
  [ngClass]="{'border-b' : !isLast}">
  <div
    class="tourHeader row flex flex-row items-center w-full px-2"
    [ngClass]="tour.state">


  <!-- tour name -->
  <div class="tourLink pl-1 pt-1 items-center flex min-w-[100px]">
    <span class="noWrap name">
    <a [routerLink]="['/tour-planner/detail/', tour.id ]" class="jump">
      {{tour.name}}<i [className]="'icon icon-jump-to'"></i></a></span>
  </div>

  <!-- start and end time -->
  <div class="startEndTime pl-2 flex flex-col min-w-[60px] text-[#848488]">
    <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'HH:mm')  : '--:--'}}</div>
    <div>{{tour?.effectiveArrivalDateTime ? (tour?.effectiveArrivalDateTime | date:'HH:mm') : '--:--'}}</div>
  </div>

  <!-- driver / resource -->
  <div class="startEndTime pl-2 flex flex-row overflow-hidden">
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">{{getDriverName(tour)}}</div>
    <div>&nbsp;/&nbsp;</div>
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">{{tour.resource?.name? tour.resource?.name: '-'}}</div>
  </div>

  <div
    [pTooltip]="getProgressToolTip(tour)"
    [tooltipOptions]="tooltipOptions"
      class="ml-auto max-w-[80px] min-w-[60px] bg-gray-200 rounded-full h-2 justify-end">
      <div
      class="bg-[#262626] h-2 rounded-full"
      [ngStyle]="{ 'width': getDonePercentage(tour) + '%' }">
    </div>
  </div>

</div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Touren gefunden'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[5, 10, 15]">
</p-paginator>
