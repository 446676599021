import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';

export const TASKS_QUERY = gql`
query getTaskQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $locationIds: [UUID],
  $fromDate: String,
  $toDate: String,
  $fromAssignmentDueDateTime: String,
  $toAssignmentDueDateTime: String,
  $fromCompletedDateTime: String,
  $toCompletedDateTime: String,
  $includeOverDue: Boolean,
  $sortDirection: String,
  $types:[String],
  $states: [TaskState]
  $handlingType: HandlingType
)
{
  tasks: getTasksPaged(
    taskFilter: { 
      types: $types,
      states: $states,
      handlingType: $handlingType,
      locationIds: $locationIds,
      fromDateTime:  $fromDate,
      toDateTime: $toDate,
      fromAssignmentDueDateTime: $fromAssignmentDueDateTime,
      toAssignmentDueDateTime: $toAssignmentDueDateTime,
      fromCompletedDateTime: $fromCompletedDateTime,
      toCompletedDateTime: $toCompletedDateTime,
      includeOverDue : $includeOverDue,
      sortRequest: { 
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection 
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
    }) {
    totalPages
      totalElements
      number
      size
      content{
        id
        dueDateTimePeriod {
          modifier
          dateTime
          fromDateTime
          toDateTime
        }
        type
        description
        state
        assignment {
          assigneeId
          assignmentType
          typeLabel
          name
          dueDateTimePeriod{
              modifier
              dateTime
              fromDateTime
              toDateTime
          }
        }
        order{
          id
          number
          customer {
            type
            companyName
            companyNameExtension
            firstName
            lastName
          }
          lineItems {
            resourceAssignments {
              resource {
                name
              }
            }
          }
        }
      }
  }
}`;

export interface TaskQueryResponse {
  tasks: Page<Task>;
  loading: boolean;
}
