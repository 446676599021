import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Task } from 'src/app/rent-module/shared/entities/task/task';

@Component({
  selector: 'app-rental-resource-assignment-refuel-form',
  templateUrl: './rental-resource-assignment-refuel-form.component.html',
  styleUrls: ['./rental-resource-assignment-refuel-form.component.scss']
})
export class RentalResourceAssignmentRefuelFormComponent {

  refuelForm: FormGroup;
  controlKey: string;
  label: string;
  suffix: string;

  _task: Task;
  @Input() set task(task: Task) {
    if(task){
      this._task = task;
      this.controlKey = this._task?.formControlTemplate?.body[0]?.controls[0]?.controls[0]?.key;
      this.label = this._task?.formControlTemplate?.body[0]?.controls[0]?.controls[0]?.settings?.label;
      this.suffix = this._task?.formControlTemplate?.body[0]?.controls[0]?.controls[0]?.settings?.suffix;
      this.buildForm();
    }

  }
  @Output() complete = new EventEmitter<{ task: Task, formModelInput?: any }>();

  constructor(
  ) {

    
  }

  buildForm() {
    this.refuelForm = new FormGroup({});
    let refuelFormControl = new FormControl( undefined, Validators.required);
    this.refuelForm.addControl(this.controlKey, refuelFormControl);
  }

  completeTask() {
    let refueledValue = {
      refueled: this.refuelForm.get(this.controlKey).value
    }
    let controlModelInput = {
      controlKey: this.controlKey,
      value : refueledValue
    };
    let controlModelInputs = [];
    controlModelInputs.push(controlModelInput);
    let formModelInput = {
      controlModelInputs: controlModelInputs
    };
    
    this.complete.emit({
      task: this._task,
      formModelInput: formModelInput
    });
  }
  
  
}
