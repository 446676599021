import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommandMenuItem, EditDialogService } from '@nexato/nx-core-module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { AddressDialogComponent } from 'src/app/shared-module/components/address-dialog/address-dialog.component';
import { Address } from 'src/app/shared-module/shared/entities/address/address';
import { AddressService } from 'src/app/tour-planning/shared/services/address/address.service';


@Component({
  selector: 'div[task-row-wide], app-task-row-wide-component',
  templateUrl: './task-row-wide.component.html',
  styleUrls: ['./task-row-wide.component.scss'],
})
export class TaskRowWideComponent {

  @Input() showFilesButton: boolean = true;
  @Input() showCommentsButton: boolean = true;

  dialogRef: DynamicDialogRef | undefined;
  @Input() task: Task;
  @Input() showCommandMenu: boolean = false;
  @Input() showIconMenu: boolean = true;
  @Output() refetchTasks = new EventEmitter();

  // dynamic labels templates
  availableColumns = [
    { field: 'dueDateTimePeriod', label: 'Fälligkeit', showSpacer: false },
    { field: 'completedDateTime', label: 'Datum abgeschlossen', showSpacer: true },
    { field: 'orderNumber', label: 'Auftragsnummer', showSpacer: true },
    { field: 'orderCustomerName', label: 'Kunde', showSpacer: true },
    { field: 'addressStreet', label: 'Straße', showSpacer: true },
    { field: 'addressPostalCode', label: 'PLZ', showSpacer: true },
    { field: 'addressCity', label: 'Stadt', showSpacer: true },
    { field: 'addessGeocodingGrade', label: 'Addressqualität', showSpacer: true },
    { field: 'addressSummary', label: 'Adresse', showSpacer: true },
    { field: 'addressNote', label: 'Adresszusatz', showSpacer: true },
    { field: 'orderLocation', label: 'Niederlassung', showSpacer: true },
    { field: 'state', label: 'Status', showSpacer: false },
    { field: 'extendedState', label: 'Status mit Zuweisung', showSpacer: false }
  ];
  public defaultColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName',
    'state',
  ];
  @Input() columns: string[] = this.defaultColumns;
  @ViewChild('dueDateTimePeriod', { static: true }) dueDateTimePeriod: TemplateRef<any>;
  @ViewChild('completedDateTime', { static: true }) completedDateTime: TemplateRef<any>;
  @ViewChild('orderNumber', { static: true }) orderNumber: TemplateRef<any>;
  @ViewChild('orderCustomerName', { static: true }) orderCustomerName: TemplateRef<any>;
  @ViewChild('addressStreet', { static: true }) addressStreet: TemplateRef<any>;
  @ViewChild('addressPostalCode', { static: true }) addressPostalCode: TemplateRef<any>;
  @ViewChild('addressCity', { static: true }) addressCity: TemplateRef<any>;
  @ViewChild('addessGeocodingGrade', { static: true }) addessGeocodingGrade: TemplateRef<any>;
  @ViewChild('addressSummary', { static: true }) addressSummary: TemplateRef<any>;
  @ViewChild('addressNote', { static: true }) addressNote: TemplateRef<any>;
  @ViewChild('orderLocation', { static: true }) orderLocation: TemplateRef<any>;
  @ViewChild('state', { static: true }) state: TemplateRef<any>;
  @ViewChild('extendedState', { static: true }) extendedState: TemplateRef<any>;
  getTemplateForColumn(column: any): TemplateRef<any> {
    if (column === 'dueDateTimePeriod') {
      return this.dueDateTimePeriod;
    } else if (column === 'completedDateTime') {
      return this.completedDateTime;
    } else if (column === 'orderNumber') {
        return this.orderNumber;
    } else if (column === 'orderCustomerName') {
      return this.orderCustomerName;
    } else if (column === 'addressStreet') {
      return this.addressStreet;
    } else if (column === 'addressPostalCode') {
        return this.addressPostalCode;
    } else if (column === 'addressCity') {
        return this.addressCity;
    } else if (column === 'addessGeocodingGrade') {
        return this.addessGeocodingGrade;
    } else if (column === 'addressSummary') {
        return this.addressSummary;
    } else if (column === 'addressNote') {
      return this.addressNote;
    } else if (column === 'orderLocation') {
      return this.orderLocation;
    } else if (column === 'state') {
      return this.state;
    } else if (column === 'extendedState') {
      return this.extendedState;
    }
    
    return undefined; // Default to text template
  }

  constructor(
    public tasksService: TasksService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: MatDialog,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private addressService: AddressService,
    public dialogService: EditDialogService,
   ){
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  buildArticleString(taskPositions: any[]){
    let articleNumberString = "";
    if(taskPositions && taskPositions.length > 0){
      for (let index = 0; index < taskPositions.length; index++) {
        const element = taskPositions[index];
        articleNumberString = articleNumberString + taskPositions[index].articleNumber;
        if(index < taskPositions.length -1){
          articleNumberString = articleNumberString + ", ";
        }
      }
    }
    return articleNumberString;
  }

  goToOrder(task: Task){
    if(task && task.order && task.order.id){
      this.router.navigate(["./orders/order/" + task.order.id]);
    }
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getAddressToolTip(address: Address) {
    if(!address) {
      return null;
    }
    let addressToolTip = address.getAddressString();
    // add the note of the address to the tooltip
    if(address.note){
      addressToolTip += ", " + address.note;
    }
    return addressToolTip;
  }

  public commandMenuItems: CommandMenuItem[] = [
    {
      label: 'Adresse bearbeiten',
      command: 'editAddress',
      disabled: function() : boolean {
        return !this.data?.address;
      }
    },
    {
      label: 'Adresse akzeptieren',
      command: 'editAddress',
      disabled: function() : boolean {
        return this.data?.address?.geocodingResultGrade !== 'YELLOW'}
    }
  ]

  executeCommand($event){
    if($event.command === 'editAddress'){
      this.editAddress($event.data);
    }
    if($event.command === 'acceptAddress'){
      this.acceptAddress($event.data);
    }
  }

  acceptAddress(task: Task) {
    //this.dsLoading = true;
    this.addressService.acceptAddress(task?.address?.id, () => {
      this.refetchTasks.emit();
    });
  }

  editAddress(task: Task) {
    this.dialogRef = this.dialogService.open(AddressDialogComponent, {
      width: "1100px",
      header: 'Adresse bearbeiten',
      data: {
        address: task.address,
      }
    });
    this.dialogRef.onClose.subscribe((result: any) => {
      if(result){
        this.addressService.updateAddress(result.addressInput, (result: any) => {
          this.refetchTasks.emit();
        });
      }
    });
  }

  // --> overlay handling -> should be moved into own component later

  public overlayRef: OverlayRef;
  public triggerBtn: any;
  public overlayBackdropSubscription: Subscription;

  openOverlay(overlayContent, triggerBtn) {
    this.triggerBtn = triggerBtn;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(triggerBtn.getNativeElement())
      .withFlexibleDimensions(true)
      .withPositions([{
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center'
      }]);
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,             // Add backdrop
      positionStrategy,
      width: '500px',
      maxHeight: '650px',
    });
    if(this.overlayRef){
      this.closeOverlay();
    }
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayBackdropSubscription = this.overlayRef.backdropClick().subscribe(() => {
      this.closeOverlay();
    });
    document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    const overlayPortal = new TemplatePortal(overlayContent, this.viewContainerRef);
    this.overlayRef?.attach(overlayPortal);
    this.positionArrow(triggerBtn);
  }

  closeOverlay() {
    this.overlayRef?.detach();
    this.triggerBtn = undefined;
    document?.removeEventListener('keydown', this.handleEscapeKey.bind(this)); 
    this.overlayBackdropSubscription?.unsubscribe();
  }

  private handleEscapeKey(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOverlay();
    }
  }

  positionArrow(triggerBtn: any) {
    const overlayBox = this.overlayRef.overlayElement.querySelector('.overlay-box');
    const arrow = overlayBox.querySelector('.overlay-arrow');

    // Get positions of the overlay box and the trigger button
    const overlayRect = overlayBox.getBoundingClientRect();
    const triggerRect = triggerBtn.getNativeElement().getBoundingClientRect();

    // Calculate the middle point of the trigger button
    const middleY = triggerRect.top + triggerRect.height / 2;

    // Set the arrow position
    this.renderer.setStyle(arrow, 'top', `${triggerBtn.getNativeElement().getBoundingClientRect().top}px`); // Center arrow vertically
    this.renderer.setStyle(arrow, 'left', `${triggerBtn.getNativeElement().getBoundingClientRect().left + 5 }px`); 
    // Position the arrow correctly based on the overlay direction
    if (overlayRect.left < triggerRect.right) {
      this.renderer.addClass(arrow, 'arrow-right'); // Arrow points to the right
    } else {
      this.renderer.addClass(arrow, 'arrow-right'); // Arrow points to the left
    }
  }
  // <-- overlay handling


  shoulShowSpacer(column: string, columns: string[], index: number, isLast: boolean){
    // if last column, no spacer needed
    if(isLast){
      return false;
    }
    let currentColumn = this.availableColumns.find((column) => column.field === columns[index]);
    let nextColumn = this.availableColumns.find((column) => column.field === columns[index + 1]);
    // if we have a next column, check if it should show a spacer
    if(nextColumn !== undefined && !nextColumn.showSpacer){
      return false;
    }
    return currentColumn?.showSpacer;
  }
}
