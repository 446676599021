
<!-- header start -->
<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="flex flex-row items-center ml-3 font-medium noWrap h-12">
    Aufgabenliste
  </div>
</div>
<!-- header end -->

<!-- filter start -->
<div class="flex flex-row pt-[0.4rem] pb-1 pl-3 pr-3 bg-white border-b">
  <div class="basis-2/3 flex flex-row gap-2">
    <nx-filter-multiSelect
      [className]="'table-filter'"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [appendTo]="'body'"
      [(ngModel)] = "typesModel"
      [options]="typeOptions"
      [showClear]="true"
      placeholder="Typ"
      (onClear)="refetchTasks()"
      (onChange)="refetchTasks()"
      optionLabel="label">
        <ng-template let-option pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <span class="ml-1 mt-1">{{ option.name }}</span>
          </div>
        </ng-template>
    </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <p-dropdown
      placeholder="Sortierung"
      [(ngModel)] = "sortModel"
      (onChange)="refetchTasks()"
      [options]="sortOptions"
      [styleClass]="'sort'"
      appendTo="body"
      optionLabel="label"
      [showClear]="false">
    </p-dropdown>
  </div>
</div>
<!-- filter end -->

<!-- tasks start -->
<div class="flex flex-col">
  <!--  -->
  <div
    [columns]="defaultColumns"
    *ngFor="let task of dataSource?.data; let isLast = last"
    task-row-wide
    [showFilesButton]="false"
    [showCommentsButton]="false"
    [showCommandMenu]="false"
    class="bg-white border-gray border-b last:border-none"
    [task]=task>
  </div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden.'">
</nx-empty-box>
<!-- tasks end -->

<!-- Pagination start -->
<p-paginator
  [dropdownAppendTo]="'body'"
  [totalRecords]="dataSource?.totalElements"
  [first]="pageModel?.pageNumber * pageModel?.pageSize"
  [rows]="pageModel?.pageSize"
  [showCurrentPageReport]="true"
  (onPageChange)="onPageChange($event)" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[15, 20, 50]">
</p-paginator>
<!-- Pagination end -->