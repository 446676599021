import { Component, Input, OnDestroy } from '@angular/core';
import { ExpandService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { ArticleDataSource } from './articleDataSource';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent implements  OnDestroy{

  public dataSource: ArticleDataSource;

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  public loading = false;

  // sorting
  public sortModel: SortModel;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Nummer aufsteigend', fieldName: 'number', direction: "asc" },
    { label: 'Nummer absteigend', fieldName: 'number', direction: "desc" },
    { label: 'Name aufsteigend', fieldName: 'name', direction: "asc" },
    { label: 'Name absteigend', fieldName: 'name', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  @Input() withMainHeadline = true;

  constructor(
    private apollo: Apollo,
    public expandService: ExpandService
  ) {
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.dataSource = new ArticleDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.loadArticles();
  }
  
  ngOnInit() {
    this.expandService.setExpandState(false);
  }

  loadArticles(): void{
    this.dataSource.loadarticles(this.buildQueryVariables());
  }

  refetchArticles(): void {
    this.dataSource.refetchQuery(this.buildQueryVariables());
  }

  buildQueryVariables(): any {
    return {
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction
    };
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchArticles();
  }
}
