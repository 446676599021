import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { PageModel, SortModel } from '../../components/task-assignment-list-unassigned/abstract-task-assignment-list';
import { Resource } from '../../shared/entities/resource/resource';
import { ResourceAssignment } from '../../shared/entities/resourceAssignment/resourceAssignment';
import { LocationService } from '../../shared/services/location/location.service';
import { ResourceAssignmentDataSource } from './resourceAssignmentDataSource';

@Component({
  selector: 'app-resource-assignments-list',
  templateUrl: './resource-assignments-list.component.html',
  styleUrls: ['./resource-assignments-list.component.scss']
})
export class ResourceAssignmentsListComponent implements OnDestroy, OnChanges {

  // pagination
  public pageModel: PageModel;
  public defaultPageModel: PageModel = { pageNumber: 0, pageSize : 15 };

  // sorting
  public sortModel: SortModel;
  public sortOptions : SortModel[] = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Startdatum aufsteigend', fieldName: 'outDateTimePeriod', direction: "asc" },
    { label: 'Startdatum absteigend', fieldName: 'outDateTimePeriod', direction: "desc" },
    { label: 'Enddatum aufsteigend', fieldName: 'inDateTimePeriod', direction: "asc" },
    { label: 'Enddatum absteigend', fieldName: 'inDateTimePeriod', direction: "desc" },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'lineItem.order.number', direction: "asc" },
    { label: 'Auftragsnummer absteigend', fieldName: 'lineItem.order.number', direction: "desc"}
  ];
  public defaultSortModel: SortModel = this.sortOptions[0];

  // types
  public orderTypesModel: any[] = [];
  public orderTypeOptions = [
    { id: 'RENTAL', name: 'Miete', label: 'Miete' }
  ];
  public defaultOrderTypesModel = undefined; // load all types by default

  // states
  public statesModel: any[] = [];
  public statesOptions = [
    { id: 'NEW', name: 'Neu', label: 'Neu' },
    { id: 'OUT', name: 'Laufend', label: 'Laufend' },
    { id: 'COMPLETED', name: 'Abgeschlossen', label: 'Abgeschlossen' },
    { id: 'ARCHIVED', name: 'Archiviert', label: 'Archiviert' }
  ];
  public defaultStatesModel = undefined; // load all types by default

  // locations - options will be generated as a result of the locationService.getLocations() call
  public locationsModel: any[] = [];
  public locationsOptions = [];
  public defaultLocationsModel = undefined; // load all locations by default

  dataSource: ResourceAssignmentDataSource;
  loading = false;

  @Input() resource: Resource;

  constructor(
    private apollo: Apollo,
    private locationService: LocationService,
    public rolesService: RolesService

  ) {
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe(locations => {
      this.locationsOptions = locations;
    });
    // no state currently stored for this page
    this.pageModel = this.defaultPageModel;
    this.sortModel = this.defaultSortModel;
    this.orderTypesModel = this.defaultOrderTypesModel;
    this.statesModel = this.defaultStatesModel;
    this.locationsModel = this.defaultLocationsModel;
    this.dataSource = new ResourceAssignmentDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.resource && this.resource.id){
      this.loadResourceAssignments();
    } else {
      this.refetchResourceAssignments();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }

  loadResourceAssignments(){
    this.dataSource?.loadResourceAssignments({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      orderLocationIds: this.locationsModel?.map(location => location.id),
      orderTypes: this.orderTypesModel?.map(type => type.id),
      states: this.statesModel?.map(state => state.id),
      resourceId: this.resource?.id
    });
  }

  refetchResourceAssignments(force = false): void {
    this.dataSource.refetchQuery({
      pageNumber: this.pageModel?.pageNumber,
      pageSize: this.pageModel?.pageSize,
      sortProperty: this.sortModel?.fieldName,
      sortDirection: this.sortModel?.direction,
      orderLocationIds: this.locationsModel?.map(location => location.id),
      orderTypes: this.orderTypesModel?.map(type => type.id),
      states: this.statesModel?.map(state => state.id),
    }, force);
  }

  public getIcon(resourceAssignment: ResourceAssignment){
    return Order.getOrderIcon(resourceAssignment?.lineItem?.order?.type + "");
  }

  public getState(resourceAssignment: ResourceAssignment){
    return ResourceAssignment.getResourceAssignmentStateStatic(resourceAssignment?.state + "");
  }

  public getSeverity(resourceAssignment: ResourceAssignment){
    return ResourceAssignment.getResourceAssignmentStateSeverity(resourceAssignment?.state + "");
  }

  public getContactName(resourceAssignment: ResourceAssignment){
    return resourceAssignment?.lineItem?.order?.customer?.getFullName() ? resourceAssignment?.lineItem?.order?.customer?.getFullName() : '-';
  }

  onPageChange(event) {
    this.pageModel = { pageNumber: event.page, pageSize: event.rows };
    this.refetchResourceAssignments();
  }

}



